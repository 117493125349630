@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap');

body {
    margin: 0;
    font-family: 'Karla', sans-serif !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: left;
    background-color: #fafbfd;
}


.chat-ui-sec {
    min-height: 100vh;
    background-color: #ffffff;
    display: flex;
    width: 100%;
    position: relative;
}

.chat-ui-sidebar {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    order: 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: 5rem;
    border-top: 0;
    border-right: 1px solid #e5e9f2;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    background-color: #ff43c0;
    position: relative;
    padding: 0.75rem 1.5rem;
    align-items: center;
    justify-content: space-between;
}

.sidebar-logo {
    background-color: #f8f9fa;
    padding: 0.375rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -0.2em;
}

.chat-sidebar-clear-btn {
    /* background-color: #f8f9fa!important; */
    background-color: transparent;
    padding: 0.375rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 !important;
}

.btn-theme-outline {
    color: #ff43c0 !important;
    border-color: #ff43c0 !important;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.4375rem 1.25rem !important;
    font-size: 1rem !important;
    cursor: pointer;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.chat-intial-content h5 {
    font-size: 1.09375rem;
    margin-bottom: 0.75rem;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
}

.chat-intial-content p {
    color: #adb5bd !important;
    font-size: .875rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.chat-intial-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.chat-ui-main-wrapper {
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    min-width: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
}

/* .chat-ui-main-layout{
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    min-width: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
} */

.chat-ui-layout {
    max-width: 800px;
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    min-width: 0;
    width: 100%;
}

.chat-ui-main-wrapper-header {
    padding: 0.75rem 2.25rem;
    border-bottom: 1px solid #e5e9f2;
    display: flex;
    justify-content: space-between;
}

.chat-ui-main-wrapper-header .title {
    display: flex;
    align-items: center;
    gap: 1em;
}

.chat-ui-main-wrapper-header h6 {
    font-size: 1em;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0 !important;
    /* text-align: justify; */
}

/* .header-profile-img {
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 10%);
    display: inline-block;
} */

.haive-profile-img {
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 10%);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 700;
    color: #fff;
}

.chat-ui-main-wrapper-body-header {
    padding: 0.75rem 2.25rem;
    display: flex;
    align-items: flex-start;
    gap: 1em;
}

.chat-ui-main-wrapper-body-header h6 {

    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0 !important;
    text-align: justify;
    white-space: pre-wrap;
}

.chat-ui-main-wrapper-body {
    background: #f7f7f8;
    height: 100%;
    flex: 2;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: calc(100vh - 160px);
    max-height: calc(100vh - 150px);
}

.chat-ui-sec ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px
}

/* background of the scrollbar except button or resizer */
.chat-ui-sec ::-webkit-scrollbar-track {
    background-color: #fff
}

.chat-ui-sec ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4
}

/* scrollbar itself */
.chat-ui-sec ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff
}

.chat-ui-sec ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.chat-ui-sec ::-webkit-scrollbar-button {
    display: none
}

.chat-ui-main-wraapper-footer {
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 13.94%, #fff 54.73%);
    width: 100%;
    position: relative;
}

.chat-ui-footer-input {
    padding: 1.5em 2em;
    border-top: 1px solid #e5e9f2;
}

.chat-ui-footer-input .input-group {
    gap: 1em;
}

.chat-ui-footer-input .chat-submit-btn {
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0.375rem !important;
    border-radius: 8px !important;
}

.chat-ui-footer-input .input-group-text {
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
}

.chat-ui-footer-input .form-control {
    border: 0 !important;
    font-weight: 500;
}

.chat-ui-footer-input .form-control:focus {
    box-shadow: none !important;
}

.chat-ui-footer-input .form-control:disabled {
    background-color: transparent !important;
}

.chat-ui-footer-input .form-control::placeholder {
    font-size: 0.9em;
    font-weight: 500;
}

.logo {
    /* width: 2.2em; */
    width: 30px;
    object-fit: contain;
    height: 30px;
}

.haive-boat-img {
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 10%);
    background-color: #ee6c04;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 700;
    color: #fff;
}

.step-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.chat-room-logo {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.user-message {
    background-color: #f7f7f8;
}

.bot-message {
    background-color: #ebebf0;
}

.message-sec {
    min-height: 2.5rem;
    display: flex;
    align-items: center;
}

.btn-theme-none {
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0.375rem !important;
    border-radius: 8px !important;
}